<template>
    <div class="container-fluid mb-4" style="margin-top:20px;">
        <ModalAsignacion
            :modal.sync="showModal"
            @updated-modal="updateCLoseModal"
            :title="titleModal"
            :editModal="editModal"
            @refresh-data-table="resetDataOfTable"
            :objectAsignacion="asignacionSelected"
            :StowagePlanningImplementVehicleId="StowagePlanningImplementVehicleId"
        />
        <ModalHistoricoAsignacion
            :modal.sync="showModalAsignacion"
            @updated-modal="updateCLoseModalAsignacion"
            :title="titleAsignacionModal"
            @refresh-data-table="resetDataOfTable"
            :listSelected="listSelected"
            :typeArray="typeArray"
            :objectAsignacion="asignacionSelected"
        />
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        
        <CRow>
            <CCol sm="12" class="d-flex align-items-center justify-content-end mb-2">
                <CButton
                    color="excel"
                    class="ml-1"
                    size="sm"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
            </CCol>
            <CCol sm="12" class="d-flex align-items-center justify-content-end">
                <CButton
                    color="add"
                    size="sm"
                    @click.prevent="toggleShowModalCreateDriver()"
                    v-c-tooltip="{ 
                        placement:'top-end', 
                        content:$t('label.AssociationGamela') 
                    }"
                    >
                    <CIcon name="cil-playlist-add"/>&nbsp; {{ $t('label.nueva') }} 
                </CButton>
                <CButton style="display: none;" v-c-tooltip="{ placement:'top-end', content:$t('label.AssociationGamela') }"></CButton>
            </CCol>
            <CCol sm="12" style="margin-top:10px;">
                <dataTableExtended
                    class="align-center-row-datatable"
                    size="lg"
                    :fields="fields"
                    :items="items"
                    :items-per-page="5"
                    column-filter
                    pagination
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :noItemsView="tableText.noItemsViewText"
                    :loading="loadingTable"
                    hover
                    sorter
                    @filtered-items-change="getFilteredList"
                    >
                    <template #loading>
                        <loading/>
                    </template>
                    <template #DriverName="{ item }">
                        <td class="text-center align-middle">
                            <div v-if="item.DriverName">
                                ({{ item.DriverCi }}) {{ item.DriverName }}
                            </div>
                            <div v-else>
                                N/A
                            </div>
                        </td>
                    </template>
                    <template #Status="{ item }">
                        <td class="text-center align-middle">
                            <div>
                                <CBadge :color="getBadge(item.Status)">
                                {{ $t("label." + item.Status) }}
                                </CBadge>
                            </div>
                        </td>
                    </template>
                    <template #Detalle="{ item }">
                        <td class="text-center">
                            <CButton
                                color="plan"
                                size="sm"
                                v-c-tooltip="{
                                    content: $t('label.historic'),
                                    placement: 'top-end',
                                }"
                                @click="toggleShowModalHistorico(item,1)"
                            >
                                <CIcon name="cil-list-rich" />
                            </CButton>
                            <CButton
                                color="watch"
                                size="sm"
                                class="ml-1"
                                v-c-tooltip="{
                                    content: $t('label.HistoryAssociation'),
                                    placement: 'top-end',
                                }"
                                @click="toggleShowModalHistorico(item,2)"
                            >
                                <CIcon name="cil-list-rich" />
                            </CButton>
                            <CButton class="ml-1" color="edit" size="sm" v-c-tooltip="{content: $t('label.edit')+' '+$t('label.AssociationGamela'),placement:'top-end'}" @click="toggleShowModalEditDriver(item)">
                                <CIcon name="pencil" />
                            </CButton>
                            <!--<CButton color="wipe" size="sm" v-c-tooltip="{content: $t('label.deletedRecords')}" @click="ConfirmarEliminado(item)" >
                                <CIcon name="cil-trash" />
                            </CButton>-->
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { DateFormater, alertPropertiesHelpers } from '@/_helpers/funciones';
    import GeneralMixin from '@/_mixins/general';
    import { mapState } from 'vuex';
    import UpperCase  from '@/_validations/uppercase-directive';
    import ModalMixin from '@/_mixins/modal';
    import ModalAsignacion from './modal-asignacion.vue';
    import ModalHistoricoAsignacion from './modal-historico-asignacion.vue';
    import Reportes from '@/_mixins/reportes';

    function data() {
        return {
            loadingTable:false,
            loadingOverlay:false,
            items: [],
            showModal: false,
            editModal: false,
            titleModal: '',
            asignacionSelected:{},
            refrescarTabla:false,
            StowagePlanningImplementVehicleId:'',
            showModalAsignacion:false,
            titleAsignacionModal:'',
            listSelected:[],
            typeArray:1,
            filteredList: [],
        }
    }
    //methods
    function toggleShowModalCreateDriver() {
        this.showModal = true;
        this.StowagePlanningImplementVehicleId = '';
        this.titleModal = this.$t('label.nueva')+' '+this.$t('label.AssociationGamela');
    }
    function getBadge(status) {
        switch (status) {
        case 'ACTIVO':
            return 'success'
        case 'INACTIVO':
            return 'danger'
        }
    }
    async function StowagePlanningImplementList () {
        this.loadingTable = true;
        this.items = [];
        
        let listado = Array;
        await this.$http.get("StowagePlanningImplementVehicle-list", { StowagePlanningId: this.StowagePlanningId,StowagePlanningResourceId:'' })
        .then(response => {
            listado = [...response.data.data];
            this.items = listado.map(listado => Object.assign({}, this.items, {
                ...listado,
                Nro: Number(listado.Nro),
                YardName: listado.YardName == null ? "" : listado.YardName,
                YardClientName: listado.YardClientName == null ? "" : listado.YardClientName,
                UltimoUsuario: listado.TransaLogin,
                FormatedDate: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
                Status: listado.Status,
                FgActImpVehAct: listado.FgActImpVehAct,
                _classes: ( listado.FgActImpVehAct ) ? '' : 'table-danger'
            }));
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingTable = false;
        });
    }
    function toggleShowModalEditDriver(item) {
        this.editModal = true;
        this.asignacionSelected = item;
        this.titleModal = this.$t('label.edit')+' '+this.$t('label.AssociationGamela')+ ': ' + item.ImplementAlias;
        this.StowagePlanningImplementVehicleId = item.StowagePlanningImplementVehicleId;
        this.showModal = true;
    }
    function toggleShowModalHistorico(item,type) {
        this.showModalAsignacion = true;
        this.listSelected = type === 1 ? item.OrdersJson : item.AssociatedJson;
        let title = item.ImplementAlias;
        this.titleAsignacionModal = (type === 1 ? this.$t('label.HistoryOrders')+': '+title : this.$t('label.HistoryAssociation')+': '+title) ;
        this.StowagePlanningImplementVehicleId = item.StowagePlanningImplementVehicleId;
        this.asignacionSelected = item;
        this.typeArray = type;
    }
    function ConfirmarEliminado(item) {
        this.$swal.fire(
        alertPropertiesHelpers(this, {
            text: `${this.$t('label.deleteQuestion')}: ${item.ImplementAlias}?`,
        })
        ).then((result) => {
            if (result.isConfirmed) {
                let res = [];
                this.loadingOverlay = true;
                let StowagePlanningImplementVehicleJson = {
                    StowagePlanningImplementVehicleId: item.StowagePlanningImplementVehicleId,
                    StowagePlanningImplementId: item.StowagePlanningImplementId,
                    StowagePlanningDriverId: item.StowagePlanningDriverId,
                    StowagePlanningVehicleId: item.StowagePlanningVehicleId,
                    BillOfLadingYardId: item.BillOfLadingYardId,
                    YardId: item.YardId,
                    ClientTpId: item.YardClientTpId,
                    Status:0
                };
                this.$http.put("StowagePlanningImplementVehicle-update", StowagePlanningImplementVehicleJson, { root: 'StowagePlanningImplementVehicleJson' })
                .then(response => {
                    res = [...response.data.data];
                    this.$notify({
                        group: 'container',
                        title: '¡'+this.$t('label.success')+'!',
                        text: res[0].Response,
                        type: "success"
                    });
                    this.loadingOverlay = false;
                    this.StowagePlanningImplementList();
                }).catch((err) => {
                    this.$notify({
                        group: 'container',
                        title: '¡Error!',
                        text: err,
                        type: "error"
                    });
                    this.loadingOverlay = false;
                });
            }
        });
    }
    function updateCLoseModal(event) {
        this.showModal = false;
        this.titleModal = '';
        this.editModal = false;
        this.StowagePlanningImplementVehicleId = '';
        this.asignacionSelected = {};
    }
    function updateCLoseModalAsignacion(event) {
        this.showModalAsignacion = false;
        this.titleAsignacionModal = '';
        this.listSelected = [];
        this.typeArray = 1;
    }
    function resetDataOfTable() {
        this.showModal = false;
        this.refrescarTabla = true;
    }
    async function onBtnExport(valor) {
        this.loadingOverlay = true;

        if(this.filteredList.length !== 0){
            
            let rowData = [];
            await this.filteredList.map(async(item,index) => {
                let objeto = {};
                objeto['#'] = index+1;
                objeto[this.$t('label.Gamela')] = item.ImplementAlias;
                objeto[this.$t('label.transport')] = item.CarrierAlias;
                objeto[this.$t('label.vehicle')] = item.LicensePlate;
                objeto[this.$t('label.driver')] = item.DriverName;
                objeto[this.$t('label.yard')] = item.YardName;
                objeto[this.$t('label.clientYard')] = item.YardClientName;
                objeto[this.$t('label.user')] = item.UltimoUsuario;
                objeto[this.$t('label.date')] = item.FormatedDate;
                objeto[this.$t('label.status')] = item.FgActImpVehAct ? this.$t('label.ACTIVO') : this.$t('label.INACTIVO');
                rowData.push(objeto);
            });

            await this.getExcelArray(rowData, this.$t('label.associationGamelaList'), valor);
            
        } else {
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }    
        this.loadingOverlay = false; 
    }
    function getFilteredList(arr) {
        this.filteredList = arr;
    }
    //computeds
    function fields(){
        let _lang = this.$i18n.locale;
        return [
            { 
                key: 'Nro', 
                label: '#',
                _style: 'width:5%; text-align:center;', filter: false, _classes: 'text-center',sorter: true
            },
            { key: 'ImplementAlias', label: this.$t('label.Gamela'),_classes:"text-center", sorter: true },
            { key: 'CarrierAlias', label: this.$t('label.transport'),_classes:"text-center", sorter: true },
            { key: 'LicensePlate', label: this.$t('label.vehicle'),_classes:"text-center", sorter: true },
            { key: 'DriverName', label: this.$t('label.driver'),_classes:"text-center", sorter: true },
            { key: 'YardName', label: this.$t('label.yard'),_classes:"text-center", sorter: true },
            { key: 'YardClientName', label: this.$t('label.clientYard'), sorter: true, _style: 'width:13%;', },
            { key: 'UltimoUsuario', label: this.$t('label.user'),_classes:"text-center",sorter: true },
            { key: 'FormatedDate', label:this.$t('label.date'), _classes:'text-center',sorter: true},
            { key: "Status", label: this.$t("label.status"), _classes: "text-center" },
            { 
                key: 'Detalle',
                label: '',
                sorter: false,
                filter: false,
                _style: 'width:1%; min-width:135px;text-align:center;'
            }
        ];
    }
    export default {
    name: "asignacion-gamela",
    data,
    props: ["value"],
    mixins: [GeneralMixin, ModalMixin, Reportes],
    directives: UpperCase,
    methods: {
        getBadge,
        StowagePlanningImplementList,
        toggleShowModalEditDriver,
        updateCLoseModal,
        resetDataOfTable,
        toggleShowModalCreateDriver,
        ConfirmarEliminado,
        updateCLoseModalAsignacion,
        toggleShowModalHistorico,
        onBtnExport,
        getFilteredList,
    },
    computed: {
        fields,
        ...mapState({
            StowagePlanningId: state => state.planificacionestiba.planificacionId,
            tabIndex: state => state.planificacionestiba.tabIndex,
            TpVessel: state => state.planificacionestiba.TpVesselId,
            user: state => state.auth.user,
        })
    },
    watch: {
        value: function (newValue, OldValue) {
            if ((newValue == 4) && !["", null, undefined].includes(this.StowagePlanning)) {
                this.StowagePlanningImplementList();
            }
        },
        refrescarTabla: function () {
            if (this.refrescarTabla) {
                this.StowagePlanningImplementList();
                this.refrescarTabla = false;
            }
        },
    },
    components: { ModalAsignacion,ModalHistoricoAsignacion }
}
</script>