<template>
    <CRow style="margin-top:20px;">
        <CCol sm="12">
            <app-modal-maquinas :machine="machine" @set-list="resetMachine"></app-modal-maquinas>
                <CCol sm="12">
                    <CRow class="mb-3">
                        <CCol sm="12">
                            <CRow>
                                <CCol sm="12" lg="4">
                                    <CSelect
                                        :label="$t('label.ubication')"
                                        addLabelClasses="required text-right"
                                        :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                                        :options="optionListUbicacion"
                                        :disabled="loading"
                                        v-model.trim="$v.filtroUbicacion.$model"
                                        :value.sync="filtroUbicacion"
                                        :is-valid="hasError($v.filtroUbicacion)"
                                        :invalid-feedback="errorMessage($v.filtroUbicacion)"
                                        @change="getData"
                                    />
                                </CCol>
                                 <CCol sm="12" lg="5">
                                    <div class="position-relative">
                                        <div class="form-group form-row" rol="group">
                                            <label class="required col-form-label col-sm-12 col-lg-3 text-right" for="tpGrua">{{$t('label.machine')}}</label>
                                            <div class="col-sm-12 col-lg-8 input-group">
                                                <v-select
                                                    label="MAQUINA"
                                                    :placeholder="$t('label.toSelect')"
                                                    :class="computedSelectMachine ? 'select-client--correct Vselect-adjust' : (verifySelect ? 'select-client--error Vselect-adjust' : 'Vselect-adjust')"
                                                    v-model="filtroMaquina"
                                                    :options="optionsDataMaquina" 
                                                    :reduce="option => option.value" 
                                                    :getOptionLabel="option => option.title"
                                                >
                                                    <template #no-options="{ }">
                                                        {{$t('label.noResultsFound')}}
                                                    </template>
                                                    <template #option="{ Json, title }">
                                                        {{ title }}
                                                        <br />
                                                        <cite>{{ Json.TpMachineName }} - {{ Json.MachineConditionName }} </cite>
                                                    </template>
                                                </v-select>
                                                <div class="input-group-append">
                                                    <div class="d-flex align-items-start">
                                                        <CButton
                                                            color="add"
                                                            size="sm"
                                                            class="d-flex align-items-center"
                                                            @click.stop="toggleMaquinaAdd"
                                                            v-c-tooltip="{
                                                                content: $t('label.add')+' '+$t('label.machine'),
                                                                placement: 'top-end'
                                                            }"
                                                            v-if="!identificadorCollapse"
                                                        >
                                                            <CIcon name="cil-playlist-add"/>
                                                        </CButton>
                                                        <CButton
                                                            v-else
                                                            color="watch"
                                                            size="sm"
                                                            class="d-flex align-items-center"
                                                            @click.stop="toggleMaquinaAdd"
                                                            v-c-tooltip="{
                                                                content: $t('label.toView')+' '+$t('label.machine'),
                                                                placement: 'top-end'
                                                            }"
                                                        >
                                                            <CIcon name="eye"/>
                                                        </CButton>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 px-0" v-if="$v.filtroMaquina.$error || (filtroMaquina == '' && verifySelect == true)">
                                                    <div class="text-invalid-feedback">
                                                    {{$t('label.required')}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CCol>
                                <CCol class="col-sm-12 col-lg-auto col-xl-auto center-field pl-0" style="text-align: right;">
                                    <CButton
                                        color="add"
                                        size="sm"
                                        class="mr-1"
                                        :disabled="isSubmit"
                                         @click="asignarPlanificacion()"
                                        v-c-tooltip="{
                                            content: $t('label.add'),
                                            placement: 'top-end'
                                        }"
                                    >
                                        <CIcon name="checkAlt"/>
                                    </CButton>
                                    <CButton
                                        color="wipe"
                                        class="justify-content-end"
                                        size="sm"
                                        v-c-tooltip="{
                                            content: $t('label.clearFilters'),
                                            placement: 'top-end'
                                        }"
                                        @click="resetMachine(true, false)" 
                                    >
                                        <CIcon name="cil-brush-alt" />
                                    </CButton>
                                </CCol>
                            </CRow>
                        </CCol>
                    </CRow>
                    <loading v-show="isLoading"/>
                </CCol>

                <CCol sm="12">
                    <dataTableExtended
                        class="align-center-row-datatable"
                        :items="formatedItems"
                        :fields="fields"
                        :table-filter="tableText.tableFilterText"
                        :items-per-page-select="tableText.itemsPerPageText"
                        sorter
                        :noItemsView="tableText.noItemsViewText"
                        :items-per-page="5"
                        pagination
                        column-filter
                        hover
                        :loading="loading"
                         
                    >
                        <template #loading>
                            <loading/>
                        </template>
                        <template #StowagePlanningId="{ index}">
                            <td class="text-center">
                                {{index+1}}
                            </td>
                        </template>
                        <template #TurnStatus="{item}">
                            <td class="text-center">
                                <CBadge :color="colorBadgePlanificacionMachine(item)">
                                    {{item.TurnStatus}}
                                </CBadge>
                            </td>
                        </template>
                        <template #acciones="{item,index}">
                            <td class="text-center">
                                <CButton
                                    color="watch"
                                    size="sm"
                                    class="mr-1"
                                    @click="editarPlanificacion(item, index)"
                                    v-c-tooltip="{
                                        content: $t('label.toView')+' '+$t('label.HourRangesMachineWork'),
                                        placement: 'top-end'
                                    }"
                                    :disabled="loading"
                                >
                                    <CIcon name="calendaryChecked"/>
                                </CButton>
                                <CButton
                                    color="edit"
                                    size="sm"
                                    class="mr-1"
                                    v-c-tooltip="{
                                        content: $t('label.edit')+' '+$t('label.machine'),
                                        placement: 'top-end'
                                    }"
                                    @click="editMachineCollapse(item)"
                                    :disabled="loading"
                                >
                                    <CIcon name="pencil"/>
                                </CButton>
                                <CButton
                                    color="wipe"
                                    size="sm"
                                    class="mr-1"
                                    v-c-tooltip="{
                                        content: $t('label.inactivateMachinePlanification'),
                                        placement: 'top-end'
                                    }"
                                    @click="eliminarPlanificacion(item, index)"
                                    :disabled="loading"
                                >
                                    <CIcon name="cil-trash" />
                                </CButton>
                            </td>
                        </template>
                    </dataTableExtended>
                </CCol>    
            <appMachinesGruas @set-list="resetMachine" :modalCraneMachine="modalCraneMachine"></appMachinesGruas>
        </CCol>
    </CRow>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import appMachinesGruas from './modals/app-machinesgruas-crud';
    import appModalMaquinas from './modals/app-modal-maquinas';
    import GeneralMixin from '@/_mixins/general';
    import {tableTextTranslatedHelpers, alertPropertiesHelpers} from '@/_helpers/funciones';
    import MachineMetalScrapValidations from '@/_validations/planificacion-estiba/MachineMetalScrapValidations';

    //data
    function data() {
        return {
            modalCraneMachine: false,
            isLoading:false,
            verifySelect: false,
            filtroMaquina:'',
            filtroUbicacion:'',
            ubicacionList:[],
            maquinaList:[],
            machine:{
                StowageUbicationId:'',
            },
            isSubmit: false,
        }
    }

    function fields(){
        return [
            { key: 'StowagePlanningId', label: '#',_style: 'width:1%; text-align:center;', filter:false },
            { key: 'MachineName',label: this.$t('label.machine'), _style:'width:10%' },
            { key: 'TpMachineName',label: this.$t('label.type'), _style:'width:10%' },
            { key: 'StowageUbicationName',label: this.$t('label.ubication'), _style:'width:10%' },
            { key: 'MachineConditionName',label: this.$t('label.condition'), _style:'width:10%' },
            { key: 'TotalHour',label: this.$t('label.EstimatedHours'), _style:'width:10%', _classes: 'cell-center text-center' },
            { key: 'TurnStatus', label: this.$t('label.status'), _style:'width:15%; text-align:center;' },
            { 
                key: 'acciones', 
                label: '', 
                _style: 'min-width: 150px; width: 1%', 
                sorter: false, 
                filter: false
            }
        ]
    }

    //methods
    function asignarPlanificacion(){
        try {
            this.isSubmit = true;
            this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
            this.verifySelect = true;
            this.$v.$touch();
            if (this.$v.filtroMaquina.$error || this.$v.filtroUbicacion.$error) {
                throw this.$t('label.errorsPleaseCheck');
            }
            let valores = {};
            let link = '';
            let json = '';
            let MachineId = this.filtroMaquina;
            let StowageUbicationId = this.filtroUbicacion;

            link = "StowagePlanningMachine-insert";
            json = "StowagePlanningMachineJson";

            valores = {
                StowagePlanningId: this.planificacionId, 
                StowageUbicationId: StowageUbicationId,
                MachineId: MachineId,
                TurnId:"",
                DateFrom:"",
                DateTo:"",
                TotalHour:"",
            }
            this.$http.ejecutar('POST', link, valores, { root: json })
                .then(response => {
                    if(response.data.status === 200){
                        const information = response.data.data;
                        if(information[0].id === ""){
                            this.$store.commit('planificacionestiba/messageMutation', information[0].Response);
                            this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                        }else{
                            this.resetMachine(true, false);
                            this.$notify({
                                group: 'container',
                                title: this.$t('label.success'),
                                text: information[0].Response,
                                type: "success"
                            })
                            this.$http.ejecutar('GET', 'StowageUbication-list', {  })
                            .then(response => {
                                this.ubicacionList = response.data.data;
                                this.filtroMaquina = '';
                                this.filtroUbicacion = '';
                                this.$store.dispatch('planificacionestiba/getStowagePlanningMachinelist',{StowagePlanningId:this.planificacionId});
                            }).catch( err => {
                                this.$store.commit('planificacionestiba/messageMutation', err);
                                this.isSubmit = false;
                                this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                            }).then(() => {
                                this.isSubmit = false;
                                this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                            })
                        }   
                    }else{
                        this.isSubmit = false;
                        this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                    }
                }).catch( err => {
                    this.isSubmit = false;
                    this.$store.state.planificacionestiba.messageError = err;
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                });
        } catch (error) {
            this.isSubmit = false;
            this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
            this.$notify({
                group: 'container',
                type: 'error',
                title: 'ERROR',
                text: error,
            });
        }
    }
    function toggleMaquinaAdd(){
        this.$store.state.planificacionestiba.tabGruaIndex = 0;
        this.$store.state.planificacionestiba.idState = this.filtroMaquina ? this.filtroMaquina : 0;
        this.modalCraneMachine = true;
    }
    async function getData(event) {
        this.filtroUbicacion = event.target.value;
        this.filtroMaquina = '';
        if(this.filtroUbicacion === '' || this.filtroUbicacion === null){
            this.maquinaList = [];
            return
        };
        this.isLoading = true;
        const valores = {
            "MachineJson":[
                {
                    "CompanyBranchId":this.branch.CompanyBranchId,
                    "TpMachineId":"",
                    "MachineConditionId":"",
                    "StowagePlanningId":this.planificacionId,
                    "StowageUbicationId":this.filtroUbicacion
                }
            ]
        }
        await this.$http.ejecutar('GET', 'MachineFreeList', { MachineJson:valores,Filter: 'ALL' }, '')
        .then(response => {
            this.maquinaList = response.data.data;
            this.isLoading = false;
        }).catch( err => {
            this.isLoading = false;
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        });
    }
    function editarPlanificacion(item){
        this.machine = item;
        this.$store.state.planificacionestiba.modalMaquinas = true;
    }
    function resetMachine(filtre, table){
        this.modalCraneMachine = false;
        if (filtre) {
            this.maquinaList = [];
            this.filtroUbicacion = '';
            this.verifySelect = false;
            this.filtroMaquina = '';
            this.$v.$reset();
        }
        if (table) {
            this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
            this.$http.ejecutar('GET', 'StowageUbication-list', {  })
                .then(response => {
                    this.ubicacionList = response.data.data;
                    this.$store.dispatch('planificacionestiba/getStowagePlanningMachinelist',{StowagePlanningId:this.planificacionId});
                 }).catch( err => {
                    this.$store.commit('planificacionestiba/messageMutation', err);
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                }).then(() => {
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                })
        }
    }
    function eliminarPlanificacion(item){
        let nombre = '';
        let valores = {};
        let link = '';
        let json = '';

        nombre = item.MachineName;
        link = "StowagePlanningMachineAll-update";
        json = "StowagePlanningMachineJson";

        valores = {
            StowagePlanningId: item.StowagePlanningId, 
            MachineId: item.MachineId,
            StowageUbicationId: item.StowageUbicationId ? item.StowageUbicationId : "",
            Status:0
        }

        if(nombre !== ''){
            this.$swal.fire(
                alertPropertiesHelpers(this, {
                    text: this.$t('label.changeStatusQuestion')+'?'
                })
               ).then((result) => {
                if (result.isConfirmed) {
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
                    this.$http.ejecutar('PUT', link, valores, { root: json })
                    .then(response => {
                        if(response.data.status === 200){
                            const information = response.data.data;
                            if(information[0].id === ""){
                                this.$store.commit('planificacionestiba/messageMutation', information[0].Response);
                                this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                            }else{
                                this.$notify({
                                    group: 'container',
                                    title: '¡'+this.$t('label.success')+'!',
                                    text: information[0].Response,
                                    type: "success"
                                })
                                this.$http.ejecutar('GET', 'StowageUbication-list', {  })
                                .then(response => {
                                    this.ubicacionList = response.data.data;
                                    this.$store.dispatch('planificacionestiba/getStowagePlanningMachinelist',{StowagePlanningId:this.planificacionId});
                                }).catch( err => {
                                    this.$store.commit('planificacionestiba/messageMutation', err);
                                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                                }).then(() => {
                                    this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                                })
                            }   
                        }else{
                            this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                        }
                    }).catch( err => {
                        this.$store.state.planificacionestiba.messageError = err;
                        this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                    });
                }
            })
        }
    }

    function tableText(){
        return tableTextTranslatedHelpers(this);
    }

    function editMachineCollapse(item){
        this.$store.state.planificacionestiba.idState = item.MachineId;
        this.modalCraneMachine = true; 
    }
    //computed
    function computedSelectMachine () {
        if (this.filtroMaquina === '' || this.filtroMaquina === null) {
            return false;
        } else {
            return true;
        }
    }
    function computedItems () {
        return this.$store.getters["planificacionestiba/myDataMaquinastable"];
    }
    function identificadorCollapse(){
        return this.filtroMaquina;
    }
    function identificador(){
        if (this.filtroMaquina === ''||this.filtroMaquina === null) {
            return true
        }else{
            return false
        }
    }
    function optionListMaquina() {
        if(this.maquinaList.length === 0){
            return [{
                title: this.$t('label.select'),
                value: '',
                Json: {
                    TpMachineName: '',
                    MachineConditionName: ''
                },
            }];
        }else{
            const chart = [{
                title: this.$t('label.select'),
                value: '',
                Json: {
                    TpMachineName: '',
                    MachineConditionName: ''
                },
            }];

            try{
                const datos = this.maquinaList;
                for (let index = 0; index < datos.length; index++) {
                    const element = datos[index];
                    chart.push({
                        title: element.MachineName,
                        value: element.MachineId,
                        Json: {
                            TpMachineName: element.TpMachineName,
                            MachineConditionName: element.MachineConditionName
                        },
                    })
                }
                return chart;
            }catch(error){
                return chart;
            }
        }
    }
    function formatedItems() {
        return this.computedItems.filter(item => {
            return item
        })
    }
    function optionListUbicacion(){
        if(this.ubicacionList.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.ubicacionList.map(function(e){
                if(e.FgActStowageUbication){
                    chart.push({
                        value: e.StowageUbicationId, 
                        label: e.StowageUbicationName,
                    })
                }
            })
            return chart;
        }
    }
    function colorBadgePlanificacionMachine(item){
        if(item.TotalHour === '00:00'){
            return 'secondary';
        }else{
            return '';
        }
    }
    //watch
    function tabIndex(newValue,OldValue){
        if(newValue === 3){
            this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
            this.verifySelect = false;
            this.$v.$reset();
            if(this.ItineraryId !== 0 || this.ItineraryId !== null){
                const Index = this.myDataItinirary.map(function(e) {
                    return e.ItineraryId; 
                }).indexOf(this.ItineraryId);
                
                if(Index > -1){
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
                    this.$http.ejecutar('GET', 'StowageUbication-list', {  })
                    .then(response => {
                        this.ubicacionList = response.data.data;
                        this.$store.dispatch('planificacionestiba/getStowagePlanningMachinelist',{StowagePlanningId:this.planificacionId});
                    }).catch( err => {
                        this.$store.commit('planificacionestiba/messageMutation', err);
                        this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                    }).then(() => {
                        this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
                    })
                }
            }
        }else if(OldValue === 3){
            this.resetMachine(true, false);
            this.filtroMaquina='';
            this.filtroUbicacion='';
            this.$store.state.planificacionestiba.collapseGruas = false;
        }
    }

    export default {
        name: 'app-maquinas',
        data,
        mixins: [GeneralMixin],
        validations: MachineMetalScrapValidations,
        props: ['loading'],
        methods: {
            asignarPlanificacion,
            editarPlanificacion,
            toggleMaquinaAdd,
            getData,
            editMachineCollapse,
            colorBadgePlanificacionMachine,
            eliminarPlanificacion,
            resetMachine,
        },
        computed: {
            optionListMaquina,
            optionsDataMaquina() {
                return this.optionListMaquina
            },
            identificadorCollapse,
            identificador,
            optionListUbicacion,
            computedItems,
            computedSelectMachine,
            formatedItems,
            fields,
            tableText,
            ...mapState({
                tabIndex: state => state.planificacionestiba.tabIndex,
                myDataItinirary: state => state.planificacionestiba.myDataItinirary,
                ItineraryId: state => state.planificacionestiba.ItineraryId,
                planificacionId: state => state.planificacionestiba.planificacionId,
                branch: state => state.auth.branch,
                bulkCarrier: state => state.planificacionestiba.bulkCarrier
            })
        },
        components: {
            appModalMaquinas,
            appMachinesGruas,
        },
        watch:{
            tabIndex,
        }
    } 
</script>
<style lang="scss">
.Vselect-adjust{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin-bottom: 0;
}
.select-client--correct {
    .vs__dropdown-toggle {
      border-color: #2eb85c !important;
    }
  }
.select-client--error {
    .vs__dropdown-toggle {
      border-color: #e55353 !important;
    }
}
.subtitle-Machine {
  padding: 10px;
  border-radius: 4px;
}
.Machine-table {
  padding: 0.75rem;
  border: 1px solid;
  border-color: #d8dbe0;
}
table.Machine-table,
table.Machine-table td,
table.Machine-table th {
  border: 1px solid #d8dbe0;
}
.bg-summary-embarque {
  background-color: #1A3760;
}
.text-color {
  color: #ffffff;
}
</style>